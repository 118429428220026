/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
@import url('https://fonts.googleapis.com/css2?family=Crimson+Text&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Crimson+Text&family=Philosopher&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Crimson+Text&family=Italianno&family=Philosopher&display=swap');
@import url('https://fonts.googleapis.com/css?family=Tangerine');
@import url('https://fonts.googleapis.com/css2?family=Crimson+Text&family=Instrument+Serif&family=Italianno&family=Philosopher&display=swap');
@import 'bootstrap/scss/bootstrap';
@import 'node_modules/animate.css/animate.min.css';
@import url('https://fonts.googleapis.com/css2?family=Candal&family=Crimson+Text&family=Instrument+Serif&family=Italianno&family=Philosopher&family=Young+Serif&display=swap');


html{
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  }
@tailwind base;
@tailwind components;
@tailwind utilities;